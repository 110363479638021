import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { switchMap, catchError, retry } from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import { Router } from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {ErrorHandlerService} from '../@continental/services/error-handler.service';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {


  isDialogOpen = false;

  constructor(
    public auth: KeycloakService,
    private router: Router,
    public alertService: ErrorHandlerService,
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | any> {

    if (request.headers && request.headers.has('Authorization')) {
      return this.makeCall(request, next);
    }

    return fromPromise(this.auth.getToken().then(
        token => {

        console.log('TokenInterceptor', token);

        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }

        return this.makeCall(request, next);

      })
    );
  }

  makeCall(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let hideError = false;

    if (request.params.has('angHideError')) {
      hideError = (request.params.get('angHideError') === 'true');
      request  = request.clone({
        params: request.params.delete('angHideError')
      });
    }

    console.log('Request', request);

    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        errorMessage = `${error.message}\nError Code: ${error.status}`;
        // window.alert(errorMessage);
        // this.openSnackBar(errorMessage);

        console.log(error.status, errorMessage);
        /* if ([501, 500].indexOf(error.status) !== -1) {
          this.router.navigateByUrl('errors/error-500');
        }
        else */

        if (!hideError) {

          switch (error.status) {
            case 404:
              // this.router.navigateByUrl('errors/error-404');
              errorMessage = 'Server return 404 error. Resource not founded.';
              break;
            case 401:
              errorMessage = 'Server return 401 error. If the problem persist try to logout and login again.';
              this.alertService.show(errorMessage, [], error.message);
              break;
            case 403:
              errorMessage = 'Server return 403 error. Try to change account to access this resource';
              this.alertService.show(errorMessage, [], error.message);
              // this.userService.clear();
              break;
            default:
              this.alertService.show(errorMessage, [], error.message);
              break;
          }
        }
        return throwError(error);
      })
    );
  }

}
